package com.shaxpeare.me.models

import com.shaxpeare.me.utils.Res

enum class Portfolio(
    val image: String,
    val title: String,
    val description: String,
    val websiteLink: String
) {
    One(
        image = Res.Image.portfolio1,
        title = "Chingari",
        description = "Web3 Social Platform",
        websiteLink = "https://chingari.io/"
    ),
    Two(
        image = Res.Image.portfolio2,
        title = "Tala",
        description = "Fintech Company",
        websiteLink = "https://tala.co/"
    ),
    Three(
        image = Res.Image.portfolio3,
        title = "Castle Global",
        description = "SaaS Company",
        websiteLink = "https://golden.com/wiki/Castle_Global-99B856E"
    ),

    Four(
        image = Res.Image.portfolio4,
        title = "Ziffi",
        description = "E-Commerce Platform",
        websiteLink = "https://www.zooty.in/business/"
    )
}
