package com.shaxpeare.me.models

import com.shaxpeare.me.utils.Constants.EXPERIENCE_CASTLE_GLOBAL
import com.shaxpeare.me.utils.Constants.EXPERIENCE_CHINGARI
import com.shaxpeare.me.utils.Constants.EXPERIENCE_TALA

enum class Experience(
    val number: String,
    val jobPosition: String,
    val description: String,
    val company: String,
    val from: String,
    val to: String
) {
    First(
        number = "01",
        jobPosition = "Lead Android Developer",
        description = EXPERIENCE_CHINGARI,
        company = "Chingari",
        from = "June 2022",
        to = "NOW",
    ),
    Second(
        number = "02",
        jobPosition = "Senior Software Developer",
        description = EXPERIENCE_TALA,
        company = "Tala",
        from = "May 2018",
        to = "April 2022",
    ),
    Third(
        number = "03",
        jobPosition = "Software Development Engineer ||",
        description = EXPERIENCE_CASTLE_GLOBAL,
        company = "Castle Global",
        from = "October 2016",
        to = "May 2018",
    )
}