package com.shaxpeare.me.utils

object Constants {
    const val WEBSITE = "https://www.linkedin.com/in/lakshminarasimhab/"
    const val SECTION_WIDTH = 1920
    const val FONT_FAMILY = "Roboto"

    const val EXPERIENCE_CHINGARI = "● Worked extensively with Kotlin Coroutines, Android Jetpack Libraries. \n" +
            "● Implemented MVVM Architecture pattern, \n" +
            "● Refactored app to a suit multi module best practices. \n" +
            "● Worked on Integrating Push notifications services by CleverTap and MoEngage. \n" +
            "● Added Analytic services such as Adjust, Firebase and Netcore suite. \n" +
            "● Developed In-house User Analytics Service to get insights of user behavior.\n" +
            "● Implemented Spam Protection by using Play Integrity and Google App Check Libraries to prevent fraud.\n" +
            "● Introduced Developer Tools to get test A/B Testing Framework efficiently. \n" +
            "● Participate in Sprint Planning and conduct daily scrum meetings. \n" +
            "● Assign tasks and Manage the sprint progress.\n" +
            "● Participate in Code Reviews and discussions to come up with optimal solutions for new features."
    const val EXPERIENCE_TALA = "● Worked extensively with Dagger 2 and RxJava2 to have optimum app performance for low end devices. \n" +
            "● Worked with Junit and Mockito to write Unit Tests for classes. \n" +
            "● Implemented Detekt and Lint scripts to help maintain code uniformity.\n" +
            "● Develop and maintain Tala Application from scratch, with over 10 million downloads and 4.4 average rating. \n" +
            "● Developed Chota Mota Pilot App from scratch to launch Tala to Bangalore city residents. \n" +
            "● Helped integrate InstaMojo SDK , to make in app payments. \n" +
            "● Developed an SDK to upload user data efficiently."
    const val EXPERIENCE_CASTLE_GLOBAL = "● Worked on implementing Model View Presenter Architecture.\n" +
            "● Integrated latest libraries like RXJava , Realm , Dagger .\n" +
            "● Added Firebase Notifications and Deep Linking for the app.\n" +
            "● Worte Manager classes for Nougat Permissions, Location Services.\n" +
            "● Worked on Native Flurry , Facebook Ads and MoPub Integration.\n" +
            "● Wrote Unit tests using Mockito for Android.\n" +
            "● Worked on In-App Billings and Purchases."
    const val EXPERIENCE_ZIFFI = "● Involved in the development of Ziffi and Ziffi for Business versions of Android applications compatible with both Mobiles and Tablets. \n" +
            "● Woked with essential Android Libraries namely Retrofit, Volley, Fused Location Services, Event-Bus, Active Android (ORM).\n" +
            "● Added Mix-Panel and Google analytics, building funnels and goal flows to track user behavior and critical screens, to identify the screens of importance and those which need improvement. \n" +
            "● Debugged critical application issues such as crashes, memory leaks and concurrency problems and improved the application performance by identifying bottlenecks in the apps and APIs and providing creative solutions, without compromising on the features. \n" +
            "● Worked closely with Designers and Product Managers to maintain strict adherence to the design principles of Android. \n" +
            "● Developed the Webpage to add, update, and maintain the FAQs section on Ziffi.com, while absorbing the architectural structuring, and design of MVC pattern of Codeigniter. "

    const val DESCRIPTION_ABOUT_ME =
        "Enthusiastic and motivated mobile application developer \n with over eight years of experience in writing apps \n" +
                "focused on their utility and practicality."
}

object Res {
    object Icon {
        const val web = "web_icon.svg"
        const val seo = "seo_icon.svg"
        const val design = "design_icon.svg"
        const val business = "business_icon.svg"
        const val apple = "apple_icon.svg"
        const val android = "android_icon.svg"
        const val link = "link_icon.svg"
        const val user = "user_icon.svg"
        const val shield = "shield_icon.svg"
        const val happy = "happy_icon.svg"
        const val checkmark = "checkmark_icon.svg"
        const val star = "star_icon.svg"
    }

    object Image {
        const val background = "background.svg"
        const val logo = "logo.svg"
        const val main = "phone.svg"
        const val about = "about_image.png"
        const val portfolio1 = "portfolio1.webp"
        const val portfolio2 = "portfolio2.webp"
        const val portfolio3 = "portfolio3.jpeg"
        const val portfolio4 = "portfolio4.webp"
    }
}